import VideoJSPlayer from "../../../../src/components/videoJsPlayer.tsx";
import MobileVideo from "../../../../src/images/mobileMenu.webm";
import SlotVideo from "../../../../src/images/slotMachine.webm";
import ProductsVideo from "../../../../src/images/products.webm";
import * as React from 'react';
export default {
  VideoJSPlayer,
  MobileVideo,
  SlotVideo,
  ProductsVideo,
  React
};